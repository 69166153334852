let Environment = {
  ControlAbi: '0x73210fBe16461929735Eb7ea114924A6eAf7573C',
  intialMintV2: '0xA2268Fcc2FE7A2Bb755FbE5A7B3Ac346ddFeDB9B',
  EarnAbi: '0x8e48d5b2Ac80d9861d07127F06BbF02F73520Ced',
  FarmAbi: '0x4590BaD61aE62ACFF33032e3Bf64b52b7264A779',
  ReserveAbi: '0xc5161aE3437C08036B98bDb58cfE6bBfF876c177',


  HydtAbi: '0x9810512Be701801954449408966c630595D0cD51',
  HygtAbi: '0x100995a7e5fFd8Ee60cc18A10C75CEe8C572c59b',
  ShydtAbi: '0xab4f1Bb558E564ae294D45a025111277c36C89c0',

  ControlResolverAbi: '0x902f25c0306cE518B7c123888304Cb67B10Ab6bE',

  base_url: "https://liveapi.opencanvas.app/v1",

  reservesContract: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE", //CoinPair BNB-USDT Pair
  quoteContract: "0x10ED43C718714eb63d5aA57B78B54704E256024E", //UniswapV2Router02 same

  routerContract: "0x10ED43C718714eb63d5aA57B78B54704E256024E", // UniswapV2Router02 same
  hydtGraph:
    "https://api.studio.thegraph.com/query/52217/hydt-protocol-mainnet/version/latest",
  claim: "0xf0c7d8b49a858855008a6d99e018f1011af5b2e6", // claim contract from afaq//////// Affiliate Withdraw contract with updated HYDT and HYGT addresses
  pancakeHygtHydt: "0xE0e9bBc7aE8EBE4D74065F6EBD710665DA285a0B", //HYDT-BNB
  // apiUrl: "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:5555/",
  // apiUrl: "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:5555/",
  // apiUrl: "https://hydtapi.quecko.com/",
  apiUrl: "https://prod-api.hydtprotocol.com/",
};

export default Environment;