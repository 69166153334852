import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Envirnoment from "../../../utils/environment";
import { useWeb3React } from "@web3-react/core";
export default function SetUserName({ setBool, bool }) {
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [inputText, setInputText] = useState("");
  const [usernameError, setUserNameError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [available, setavailable] = useState(false);
  const [availabilityChecked, setAvailabilityChecked] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("accessToken");
  const { account } = useWeb3React();
  const handleInputChange = (e) => {
    const inputText = e.target.value;
    const regex = /^[a-zA-Z0-9]*$/;

    if (regex.test(inputText)) {
      setUserNameError("");

      setAvailabilityChecked(false);
      setavailable(false);
      setInputText(inputText);
    } else {
      // Display an error message or take appropriate action
      setAvailabilityChecked(true);
      setavailable(false);

      setUserNameError("Special Characters Are Not Allowed!");
    }
  };
  const handleClearClick = () => {
    if (!available) {
      setAvailabilityChecked(false);
      setavailable(false);
      setInputText("");
    }
  };

  function checkNameAvail() {
    if (!inputText) {
      toast.error("Please enter a name");
      return;
    } else {
      setIsLoading(true);

      axios
        .get(Envirnoment.apiUrl + "users/checkUsernameAvailability", {
          params: {
            userName: inputText,
          },
        })
        .then((response) => {
          // Handle the API response here
          console.log(response.data);
          setavailable(response?.data?.isAvailable);
          setAvailabilityChecked(true);
          // You can perform additional actions based on the API response
        })
        .catch((error) => {
          if (
            error?.response?.status == 409 ||
            error?.response?.data?.message?.includes("Username already exists")
          ) {
            setavailable(false);
            setAvailabilityChecked(true);
          }

          // Handle API errors here
          console.error("Error checking username availability:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function setNameFunc() {
    if (!inputText) {
      toast.error("Please enter a name");
      return;
    }
    if (!available) {
      toast.error("Name not Available");
      return;
    }
    setIsConfirmLoading(true);
    let data = {
      userName: inputText,
    };
    axios
      .patch(Envirnoment.apiUrl + "users/register-username", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setBool(!bool);
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
        console.log(response.data);
        // setavailable(response?.data?.isAvailable);
        toast.success("Name is set successfully");
        handleClose2();
        handleShow3();
        // You can perform additional actions based on the API response
      })
      .catch((error) => {
        // Handle API errors here
        toast.error(error.request?.statusText);
        console.error("Error checking username availability:", error);
      })
      .finally(() => {
        setIsConfirmLoading(false);
      });
  }
  // useEffect(() => {

  //     setAcceessToken(accessToken);
  // }, [])

  return (
    <>
      {" "}
      <button className="get-btn" onClick={handleShow2}>
        Set Your Username
      </button>
      <Modal
        className="request-withdraw-modal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Username</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="firsttimeuser">
            <h6>
              Setting username will allow you to obtain referral link with your
              username for easy identification.
            </h6>
            <h6>
              Username will be associated to your wallet address and cannot be
              changed later.
            </h6>
            <div className="option-field">
              <label>Input your username</label>
              <div className="option-field option-field1">
                <input
                  value={inputText}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Username "
                  className={
                    available && availabilityChecked
                      ? "has-text-success"
                      : !available && availabilityChecked
                        ? "has-text"
                        : ""
                  }
                />
                {isLoading ? (
                  <button className="btn-checkk dfaf loading">
                    Check Availability
                  </button>
                ) : (
                  <button
                    disabled={!inputText}
                    className={
                      inputText ? "btn-checkk dfaf" : "btn-checkk dfaf disable"
                    }
                    onClick={checkNameAvail}
                  >
                    Check Availability
                  </button>
                )}
                {inputText && (
                  <a onClick={handleClearClick}>
                    {!available && availabilityChecked ? (
                      <img
                        src={"/assests/close-red.svg"}
                        alt="img"
                        className="img-fluid close-red"
                        style={{ right: "171px" }}
                      />
                    ) : available && availabilityChecked ? (
                      <img
                        src={"/assests/tick.svg"}
                        alt="img"
                        className="img-fluid close-red"
                        style={{ right: "171px" }}
                      />
                    ) : null}
                  </a>
                )}
              </div>
              {/* <small className="text-danger">{usernameError}</small> */}
            </div>
            <div className="bottom-refferallink">
              <label>Your Referral Link</label>
              <h6>
                https://app.hydtprotocol.com/HYDT/earn?ref=
                {user?.userName || account}
              </h6>
            </div>
          </div>
          <div className="bottom-btns">
            <button
              className="btn-cancel"
              onClick={() => {
                handleClose2();
                setavailable(false);
                setInputText("");
              }}
            >
              Cancel
            </button>
            {isConfirmLoading ? (
              <button className="btn-withdraw loading">Confirm</button>
            ) : (
              <button
                disabled={!available}
                className={available ? "btn-withdraw" : "btn-withdraw disable"}
                onClick={() => {
                  setNameFunc();
                }}
              >
                Confirm
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="request-withdraw-modal"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Username</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="request-completed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="79"
              viewBox="0 0 79 79"
              fill="none"
            >
              <path
                d="M39.4999 6.58337C21.3214 6.58337 6.58325 21.3208 6.58325 39.5C6.58325 57.6793 21.3214 72.4167 39.4999 72.4167C57.6805 72.4167 72.4166 57.6793 72.4166 39.5C72.4166 21.3208 57.6798 6.58337 39.4999 6.58337ZM33.4617 56.4087L18.9678 41.9155L23.7987 37.0846L33.4617 46.747L55.2012 25.0068L60.032 29.8377L33.4617 56.4087Z"
                fill="#50BF29"
              />
            </svg>
            <h6>Username successfully set!</h6>
            <button className="btn-okay" onClick={handleClose3}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
